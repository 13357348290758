/* eslint-disable import/prefer-default-export */
import * as React from "react";
import "../../src/css/Akzidenz-grotesk-black.css";
import "../../src/css/Akzidenz-grotesk-black.woff";
import "../../src/css/main-style.css";
import TopLayout from "./TopLayout";

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};
