import { red } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const purples = [
  "rgba(141, 66, 246, 0.25)",
  "rgba(141, 66, 246, 0.35)",
  "rgba(141, 66, 246, 0.5)",
  "rgba(141, 66, 246, 0.75)",
  "rgba(141, 66, 246, 1)",
];

export const greys = [
  "rgba(141, 141, 141, 0.25)",
  "rgba(141, 141, 141, 0.35)",
  "rgba(141, 141, 141, 0.5)",
  "rgba(141, 141, 141, 0.75)",
];

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#8d42f6",
    },
    secondary: {
      main: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#FAFAFA",
    },
    transparent: {
      gray: "rgba(52, 52, 52, 0.8)",
      white1: "rgba(255, 255, 255, 0.9)",
      white2: "rgba(255, 255, 255, 0.7)",
    },
  },
  typography: {
    fontFamily: "Questrial, sans-serif",
    h1: {
      fontFamily: "Roboto, Akzidenz Grotesk Black, sans-serif",
      fontWeight: 500,
      letterSpacing: "0.2em",
    },
    h2: {
      fontFamily: "Roboto, Akzidenz Grotesk Black, sans-serif",
      fontWeight: 400,
      letterSpacing: "0.2em",
    },
    h3: {
      fontFamily: "Roboto, Akzidenz Grotesk Black, sans-serif",
      fontWeight: 400,
      letterSpacing: "0.15em",
    },
    h4: {
      fontFamily: "Roboto, Akzidenz Grotesk Black, sans-serif",
      letterSpacing: "0.15em",
    },
    h5: {
      fontFamily: "Roboto, Akzidenz Grotesk Black, sans-serif",
      letterSpacing: "0.1em",
    },
    h6: {
      fontFamily: "Roboto, Akzidenz Grotesk Black, sans-serif",
      letterSpacing: "0.1em",
    },
    subtitle1: {
      fontFamily: "Roboto, Akzidenz Grotesk Black, sans-serif",
      letterSpacing: "0.2em",
    },
    subtitle2: {
      fontFamily: "Roboto, Akzidenz Grotesk Black, sans-serif",
      letterSpacing: "0.2em",
    },
    body1: {
      fontSize: "1.3em",
    },
  },
});

export default responsiveFontSizes(theme);
